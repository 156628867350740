import React from "react";
import { useSelector } from "react-redux";

const PermissionCheck = ({ params, children, returText = "" }) => {
  const userRole = useSelector((store) => store.userRole); // permissions'ı al

  const permissions = userRole.userRole.permissions;

  // İzin kontrolü
  const hasPermission = params.some((param) => permissions?.includes(param)); // params dizide var mı?
  //const hasPermission = permissions?.includes(params); // params dizide var mı?

  return hasPermission ? children : returText; // İzin varsa çocuk bileşenleri göster
};

export default PermissionCheck;
