import axios, { Axios } from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Functions, varJS } from "../actions/actions";

const initialState = {
  userInformation: "",
};

export const userInformationAction = createAsyncThunk(
  "userInformationAction",
  async () => {
    const token = Functions.getLocalStorageItemWithExpiry("token");
    const config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
    };
    const response = await axios.get(
      varJS.apiUrl + "api/get_user_info",
      config
    );

    return response.data;
  }
);

const userInformationSlice = createSlice({
  name: "autToken",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userInformationAction.fulfilled, (state, action) => {
        // işlem başarılı olduğunda yapılacak işlemler
        state.userInformation = action.payload;
      })
      .addCase(userInformationAction.pending, (state) => {
        // işlem başladığında yapılacak işlemler
        //state.userInfo = {};
      })
      .addCase(userInformationAction.rejected, (state) => {
        // işlem hatalı olursa yapılacak işlemler
        //state.userInfo = {};
      });
  },
});

export const { lang } = userInformationSlice.actions;

export default userInformationSlice.reducer;
