import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toogle: false,
};

const navigationSlice = createSlice({
  name: "navTog",
  initialState,
  reducers: {
    toggleAction: (state) => {
      state.toogle = !state.toogle;
      // document.body.className = state.toogle ? "smallNav" : "";
    },
  },
});

export const { toggleAction } = navigationSlice.actions;

export default navigationSlice.reducer;
