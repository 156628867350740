import axios, { Axios } from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Functions, varJS } from "../actions/actions";

const initialState = {
  userRole: {
    role: "",
    permissions: [],
  },
};

export const userRoleAction = createAsyncThunk("userRoleAction", async () => {
  const token = Functions.getLocalStorageItemWithExpiry("token");
  const config = {
    headers: {
      Authorization: `Bearer ` + token,
    },
  };
  const response = await axios.get(varJS.apiUrl + "api/permissions", config);

  return response.data;
});

const userRoleSlice = createSlice({
  name: "autToken",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userRoleAction.fulfilled, (state, action) => {
        // işlem başarılı olduğunda yapılacak işlemler
        state.userRole = action.payload;
      })
      .addCase(userRoleAction.pending, (state) => {
        // işlem başladığında yapılacak işlemler
        //state.userInfo = {};
      })
      .addCase(userRoleAction.rejected, (state) => {
        // işlem hatalı olursa yapılacak işlemler
        //state.userInfo = {};
      });
  },
});

export const { lang } = userRoleSlice.actions;

export default userRoleSlice.reducer;
