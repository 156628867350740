import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: null,
};

const notificationsSlice = createSlice({
  name: "notificationsAction",
  initialState,
  reducers: {
    notificationsAction: (state, params) => {
      state.notifications = params.payload;
    },
  },
});

export const { notificationsAction } = notificationsSlice.actions;

export default notificationsSlice.reducer;
