import axios, { Axios } from "axios";
import { message } from "antd";
import { pageLoadingAction } from "../control/pageLoading";
import { Functions, langUseFunctions } from "../actions/actions";

// GET Public (static calendar ...vb)
const getPublicAction = async (url) => {
  const config = {};

  const response = await axios
    .get(url, config)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      if (error.response.data.message == "Unauthenticated.") {
        Functions.localStorageRemove();
      }
      return error;
    });
  return response.data;
};

// GET
const getAction = async (url, dispatch) => {
  dispatch(pageLoadingAction(true));
  const token = Functions.getLocalStorageItemWithExpiry("token");
  const config = {
    headers: {
      Authorization: `Bearer ` + token,
    },
  };

  const response = await axios
    .get(url, config)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      if (error.response.data.message == "Unauthenticated.") {
        Functions.localStorageRemove();
      }
      return error;
    });
  dispatch(pageLoadingAction(false));
  return response.data;
};

// POST
const postAction = async (url, term, loadingMessage) => {
  if (loadingMessage) {
    loadingMessage.openMessage("Yükleniyor...");
  }
  const token = Functions.getLocalStorageItemWithExpiry("token");
  //Functions.popupMessage("loading", loadingMessage);
  const config = {
    headers: {
      Authorization: `Bearer ` + token,
      "Content-Type": "application/x-www-form-urlencoded",
      data: term,
    },
  };

  const response = await axios
    .post(url, term, config)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      if (loadingMessage) {
        Functions.popupMessage("error", error.response.data.message);
      }
      console.log(error);
      return error;
    });
  if (loadingMessage) {
    setTimeout(() => {
      loadingMessage.closeMessage();
    }, 500);
  }

  return {
    ...response.data,
    status: response.status,
  };
};

// DELETE
const deleteAction = async (url, term) => {
  const token = Functions.getLocalStorageItemWithExpiry("token");

  const config = {
    headers: {
      Authorization: `Bearer ` + token,
    },
  };

  const response = await axios
    .delete(url + term, config)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
  //return response.data;
};

// PUT
const putAction = async (url, term) => {
  const token = Functions.getLocalStorageItemWithExpiry("token");

  const config = {
    headers: {
      Authorization: `Bearer ` + token,
      "Content-Type": "application/x-www-form-urlencoded",
      data: term,
    },
  };

  const response = await axios.post(url, term, config).then().catch();
  return response.data;
};

export default {
  getAction,
  postAction,
  deleteAction,
  putAction,
  getPublicAction,
};
