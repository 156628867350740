import React, { createContext, useState, useEffect } from "react";

const TableSizeContext = createContext();

const TableSizeProvider = ({ children }) => {
  const [columnWidth, setColumnWidth] = useState({ middle: 400 });
  const [maxChars, setMaxChars] = useState({ middle: 60 });
  const [tooltipwWidth, setTooltipwWidth] = useState("500px");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setColumnWidth({ middle: 200 });
        setMaxChars({ middle: 50 });
        setTooltipwWidth("90%");
      } else {
        setColumnWidth({ middle: 500 });
        setMaxChars({ middle: 100 });

        setTooltipwWidth("500px");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <TableSizeContext.Provider value={{ columnWidth, maxChars, tooltipwWidth }}>
      {children}
    </TableSizeContext.Provider>
  );
};

export { TableSizeContext, TableSizeProvider };
