import { useState } from "react";
import Functions from "../../actions/functions";

export default function useToken() {
  const [token, setToken] = useState(
    Functions.getLocalStorageItemWithExpiry("token")
  );

  const saveToken = (userToken) => {
    Functions.setLocalStorageItemWithExpiry("token", userToken.token, 1);
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token,
  };
}
