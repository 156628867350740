import TR from "antd/es/locale/tr_TR";
// import TR from "antd/es/date-picker/locale/tr_TR";

const lang = (params) => {
  if (params == "en-gb" || params == "en") {
    return null;
  } else {
    return TR;
  }
};

export default { lang };
