import React from "react";
import { useSelector } from "react-redux";

const LangFunction = ({ page, type, customLang, replaceText }) => {
  const { changeLang } = useSelector((store) => store.changeLang);
  const { lang } = useSelector((store) => store.languagedata);

  let result = "";
  if (customLang) {
    result = lang[customLang][page][type] ? lang[customLang][page][type] : "";
  } else {
    result = lang[changeLang][page][type] ? lang[changeLang][page][type] : "";
  }

  if (replaceText) {
    result = result.replace("$1", replaceText);
  }

  // return <>{result}</>;

  return result.includes("\n") ? (
    <>
      {result.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </>
  ) : (
    <>{result}</>
  );
};

export default LangFunction;
