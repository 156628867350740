import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  curentLang: false,
};

const curentLangSlice = createSlice({
  name: "curentLangAction",
  initialState,
  reducers: {
    curentLangAction: (state, params) => {
      state.curentLang = params.payload;
    },
  },
});

export const { curentLangAction } = curentLangSlice.actions;

export default curentLangSlice.reducer;
