// MessageContext.js
import React, { createContext } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";

const MessageContext = createContext();
const loadingKey = "unique_loading_message_Load_pop";
export const MessageProvider = ({ children }) => {
  const { changeLang } = useSelector((store) => store.changeLang);
  const { lang } = useSelector((store) => store.languagedata);

  const openMessage = (text) => {
    const result = lang[changeLang]["global"]["fetch_progress"]
      ? lang[changeLang]["global"]["fetch_progress"]
      : "";
    message.open({
      key: loadingKey,
      type: "loading",
      content: result,
      duration: 0,
    });
    message.config({
      prefixCls: "custom-prosess-Loading",
    });
  };

  const closeMessage = () => {
    message.destroy(loadingKey);
  };

  return (
    <MessageContext.Provider value={{ openMessage, closeMessage }}>
      {children}
    </MessageContext.Provider>
  );
};

export const useMessage = () => {
  const context = React.useContext(MessageContext);
  if (context === undefined) {
    throw new Error("useMessage must be used within a MessageProvider");
  }
  return context;
};
