import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import { IoWarningOutline } from "react-icons/io5";

import "./UserIformationBox.scss";

const UserIformationBox = () => {
  const [api, contextHolder] = notification.useNotification();
  const userInformation = useSelector((store) => store.userInformation);

  useEffect(() => {
    if (userInformation?.userInformation?.subscription?.status) {
      openNotificationWithIcon(
        "error",
        userInformation?.userInformation?.subscription?.message
      );
    }
  }, [userInformation]);

  const openNotificationWithIcon = (type, text) => {
    const today = new Date().toISOString().split("T")[0];
    const lastShownDate = localStorage.getItem("lastNotificationDate");

    if (lastShownDate !== today) {
      api[type]({
        message: text,
        description: "",
        className: "error-notification",
        icon: <IoWarningOutline style={{ color: "#ff4d4f", fontSize: 28 }} />,
        duration: 0,
      });

      localStorage.setItem("lastNotificationDate", today);
    }
  };

  return <span>{contextHolder}</span>;
};

export default UserIformationBox;
