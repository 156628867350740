import { createSlice } from "@reduxjs/toolkit";
import Functions from "../actions/functions";

let getLangStorage = Functions.getLocalStorageItemWithExpiry("lang");
if (!getLangStorage) {
  Functions.setLocalStorageItemWithExpiry("lang", "tr", 1);
}
getLangStorage = Functions.getLocalStorageItemWithExpiry("lang");

const initialState = {
  changeLang: getLangStorage,
};

const changeLangSlice = createSlice({
  name: "changeLangAction",
  initialState,
  reducers: {
    changeLangAction: (state, params) => {
      Functions.setLocalStorageItemWithExpiry("lang", params.payload, 1);
      state.changeLang = params.payload;
    },
    changeLangValueAction: (state, params) => {
      Functions.setLocalStorageItemWithExpiry("lang_val", params.payload, 1);
      //localStorage.setItem("lang_val", JSON.stringify(params.payload));
    },
  },
});

export const { changeLangAction, changeLangValueAction } =
  changeLangSlice.actions;

export default changeLangSlice.reducer;
