import { configureStore } from "@reduxjs/toolkit";
import navigationReducer from "./control/navigationSlice";
//import pageFilterReducer from "./control/pageFilter";
import pageLoadingReducer from "./control/pageLoading";
import notificationsReducer from "./control/notifications";
import curentLangReducer from "./control/curentLang";
import userRoleReducer from "./control/userRole";
import userInformationReducer from "./control/userInformation";
import changeLangReducer from "./control/changeLang";
import languageReducer from "./control/languages";

// Use object literal shorthand syntax to define the object shape

export const store = configureStore({
  reducer: {
    header: navigationReducer,
    //filter: pageFilterReducer,
    languagedata: languageReducer,
    changeLang: changeLangReducer,
    pageLoading: pageLoadingReducer,
    notifications: notificationsReducer,
    curentLang: curentLangReducer,
    userRole: userRoleReducer,
    userInformation: userInformationReducer,
  },
});
