import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLanguages } from "./control/languages.js";
import { userRoleAction } from "./control/userRole";
import { userInformationAction } from "./control/userInformation";
import { useLocation } from "react-router-dom";
import Root from "./Root.js";

const Navigation = React.lazy(() => import("./layout/Navigation/Navigation"));
const Topnav = React.lazy(() => import("./layout/Topnav/Topnav"));
import { Layout, Spin, ConfigProvider } from "antd";
const Login = React.lazy(() => import("./views/Login/Login"));
const ResetPassword = React.lazy(() =>
  import("./views/ResetPassword/ResetPassword")
);
const EmailVerify = React.lazy(() => import("./views/EmailVerify/EmailVerify"));
// const LandingPage = React.lazy(() => import("./views/LandingPage/LandingPage"));
const StaticCalendar = React.lazy(() =>
  import("./views/StaticCalendar/StaticCalendar")
);
import useToken from "./views/Login/useToken.js";
import es_ES from "antd/es/locale/es_ES";
// Ant design datepicker languages
import datePickerLang from "./actions/datePickerLang";
// import MobileNav from "./layout/MobileNav/MobileNav.js";

import { MessageProvider } from "./components/MessageContext/MessageContext";
import UserIformationBox from "./components/UserIformationBox/UserIformationBox";
import { TableSizeProvider } from "./context/TableSizeContext.js";

const { Content } = Layout;

function App() {
  const [langDatas, setLangDatas] = useState();
  const { changeLang } = useSelector((store) => store.changeLang);
  const location = useLocation();
  const getLang = useDispatch();
  const navuserRole = useDispatch();
  const userInfo = useDispatch();
  const { pageLoading } = useSelector((store) => store.pageLoading);
  const { curentLang } = useSelector((store) => store.curentLang);
  const { token, setToken } = useToken();

  const languageData = async (params) => {
    return await getLang(getLanguages());
  };

  useEffect(() => {
    languageData().then((res) => {
      setLangDatas(res?.payload);
    });
  }, []);

  const userRoleData = async (params) => {
    return await navuserRole(userRoleAction());
  };

  const userInformation = async (params) => {
    return await userInfo(userInformationAction());
  };

  useEffect(() => {
    if (token) {
      userRoleData();
      userInformation();
    }
  }, [token]);

  // if (location.pathname == "/") {
  //   console.log("yes");
  //   return <LandingPage />;
  // }

  const path = window.location.pathname; // Get the current URL path

  if (path.includes("company-calendar")) {
    return <StaticCalendar langDataSet={langDatas} />;
  } else if (path.includes("password/reset")) {
    return <ResetPassword langDataSet={langDatas} setToken={setToken} />;
  } else if (path.includes("email/verify")) {
    return <EmailVerify langDataSet={langDatas} setToken={setToken} />;
  } else if (!token) {
    return <Login langDataSet={langDatas} setToken={setToken} />;
  }

  //setLoginedControl(true);

  return (
    <React.Suspense fallback={""}>
      <Layout
        className="mainLayout"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Navigation />
        <Layout className="rightLayout">
          <Topnav />
          {curentLang ? (
            <Spin
              spinning={pageLoading}
              className="pageLoding"
              size="large"
              id="mainContentArea"
            >
              <Content className="mainContent">
                <ConfigProvider locale={datePickerLang.lang(changeLang)}>
                  <MessageProvider>
                    <TableSizeProvider>
                      <div className="mainInner">
                        <Root />
                      </div>
                    </TableSizeProvider>
                  </MessageProvider>
                </ConfigProvider>
              </Content>
              <UserIformationBox />
            </Spin>
          ) : null}
          {/* <MobileNav /> */}
        </Layout>
      </Layout>
    </React.Suspense>
  );
}

export default App;
