import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageLoading: false,
};

const pageLoadingSlice = createSlice({
  name: "pageLoadingAction",
  initialState,
  reducers: {
    pageLoadingAction: (state, params) => {
      state.pageLoading = params.payload;
    },
  },
});

export const { pageLoadingAction } = pageLoadingSlice.actions;

export default pageLoadingSlice.reducer;
