const langUseFunctions = (
  lang,
  changeLang,
  page,
  type,
  customLang,
  replaceText
) => {
  let result = "";
  if (customLang) {
    result = lang[customLang][page][type] ? lang[customLang][page][type] : "";
  } else {
    result = lang[changeLang][page][type] ? lang[changeLang][page][type] : "";
  }

  if (replaceText) {
    result = result.replace("$1", replaceText);
  }

  return result;
};

export default langUseFunctions;
