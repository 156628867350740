import React from "react";
import axios, { Axios } from "axios";
import moment from "moment";
import dayjs from "dayjs";
import { message } from "antd";
import { useCallback } from "react";

// Array value filter & result label
const valueToLabel = (array, params, returnName) => {
  let result = array.filter((item) => item.value == params);
  return result[0][returnName];
};

// Array multi value filter & result label
const valueToMultiLabel = (array, params) => {
  let newArray = [];
  for (let index = 0; index < params.length; index++) {
    const element = params[index];
    const num = array.map((e) => e.value).indexOf(element);

    if (num > -1) {
      newArray.push(array[num].label);
    } else {
      newArray.push(element);
    }
  }
  const labels = newArray
    .map(function (obj) {
      return " " + obj;
    })
    .toString();

  return labels;
};

// hour and minutes HH:mm
const timeRange = (params) => {
  let rangeTime = "";
  for (let index = 0; index < params.length; index++) {
    const element = params[index];
    const now = new Date(element);
    const hour = now.getHours().toString().padStart(2, "0");
    const minute = now.getMinutes().toString().padStart(2, "0");
    rangeTime += (index != 0 ? "-" : "") + hour + ":" + minute;
  }

  return rangeTime;
};

// [05:00-10:00] split "-" to date time format
const timeRangSplit = (params) => {
  let rangeTime = [];
  const myArray = params.split("-");
  for (let index = 0; index < myArray.length; index++) {
    const time = myArray[index];
    const hour = time.split(":");

    const dateString = "Tue May 16 2017 15:34:23 GMT+0300 (FLE Daylight Time)";
    const m = moment(dateString, "ddd MMM D YYYY HH:mm:ss ZZ");
    m.set({ h: hour[0], m: hour[1] });
    rangeTime.push(dayjs(m.toDate().toString()));
  }
  return rangeTime;
};

const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const setLocalStorageItemWithExpiry = (key, value, exdays) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    //expiry: now.getTime() + exdays * 24 * 60 * 60 * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getLocalStorageItemWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

const localStorageRemove = () => {
  localStorage.removeItem("token");
  location.reload();
};
const loadingKey = "unique_loading_message_popup";
const popupMessage = (type, text) => {
  if (type == "error") {
    message.error(text);
  } else if (type == "loading") {
    message.open({
      key: loadingKey,
      type: "loading",
      content: text,
      duration: 0,
    });
  } else if (type == "loading-finished") {
    message.destroy(loadingKey);
  } else {
    message.success(text);
  }
};

const copyText = async (text) => {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(text);
    popupMessage("success", "Copied " + text);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Move textarea out of the viewport so it's not visible
    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      popupMessage("success", "Copied " + text);
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
};

const dateFunc = (date, type) => {
  return moment(date).format(type);
};

const dateCreateOrEdit = (param) => {
  const date = new Date(param);

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = days[date.getDay()];
  const month = months[date.getMonth()];
  const dayOfMonth = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const formattedDate = `${day} ${month} ${dayOfMonth} ${year} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
};

const phoneWidthCode = (code, phone) => {
  let result = phone;
  if (code) {
    result = code + " " + phone;
  }
  return result;
};

const phonewithoutCode = (params) => {
  const [part1, ...rest] = params.split(" ");
  const part2 = rest.join(" ");

  return {
    phone: part2,
    phone_code: part1,
  };
};

const getFileNameFromUrl = (url) => {
  return url.split("/").pop(); // Son "/" karakterinden sonraki kısmı alır
};

const deletedSelectItemFilter = (input, option) => {
  const regex = new RegExp(input, "i");

  // Eğer option.label bir React elementi ise, text içeriklerini çıkart
  let textContent = "";
  if (React.isValidElement(option?.label)) {
    textContent = option.label.props.children; // <span> içindeki metni al
  } else {
    textContent = option?.label ?? ""; // Doğrudan string olan metni al
  }

  return regex.test(textContent);
};

const labelToMultiValue = (inputArray, comfortList) => {
  const values = new Set();

  inputArray.forEach((input) => {
    const existingOption = comfortList.find(
      (item) => item.label.toLowerCase() === input.toLowerCase()
    );
    if (existingOption) {
      values.add(existingOption.value);
    } else {
      values.add(input);
    }
  });

  return Array.from(values);
};

export default {
  valueToLabel,
  valueToMultiLabel,
  timeRange,
  setCookie,
  getCookie,
  popupMessage,
  setLocalStorageItemWithExpiry,
  getLocalStorageItemWithExpiry,
  localStorageRemove,
  timeRangSplit,
  copyText,
  dateFunc,
  dateCreateOrEdit,
  phoneWidthCode,
  phonewithoutCode,
  getFileNameFromUrl,
  deletedSelectItemFilter,
  labelToMultiValue,
};
