import React from "react";
import "./LoadingAnimation.scss"; // CSS'i ayrı bir dosyada tutacağız

const LoadingAnimation = () => {
  return (
    <span className="load-v1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="196"
        height="196"
        viewBox="0 0 196 196"
        fill="none"
      >
        <g clipPath="url(#clip0_26_299)">
          <path
            d="M196 98C196 152.124 152.124 196 98 196C43.8761 196 0 152.124 0 98C0 43.8761 43.8761 0 98 0C152.124 0 196 43.8761 196 98Z"
            fill="#194e78"
          />
          <mask
            id="mask0_26_299"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="196"
            height="196"
          >
            <path
              d="M196 98C196 152.124 152.124 196 98 196C43.8761 196 0 152.124 0 98C0 43.8761 43.8761 0 98 0C152.124 0 196 43.8761 196 98Z"
              fill="#194e78"
            />
          </mask>
          <g mask="url(#mask0_26_299)">
            <path
              className="triangle"
              d="M104.125 -2.04114V147.001H0L104.125 -2.04114Z"
              fill="white"
            />
            <path
              className="triangle"
              d="M116.375 36.75V147H196L116.375 36.75Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_26_299">
            <rect width="196" height="196" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default LoadingAnimation;
